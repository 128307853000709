.hero-text{
    color: whitesmoke !important;
    
}
.bold{
    font-weight: 600;
}
.headshot{
    margin-top: 100px;
    width: 300px;
    border-radius: 200px;
    /* box-shadow: 10px 10px 5px #ccc; */
    box-shadow: 0px -10px 30px #000000;
 
}
.hero-image{
    height: 500px;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 50%, transparent 100%);
    width: 100vh;
}
/* .hero-cont{
    width: 100%;
} */
.container{
    display: flex;
}


/* @media only screen and (max-width: 600px) {
    .container{
        flex-direction: column;
    }
    .hero-info{
       
    }
} */