.App{
    min-height: 90vh;
    position: relative;
}
.footer{
    position: absolute;
    bottom: -75px;
    width: 100%;
    display: flex;
    justify-content: center;
}